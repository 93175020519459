function factory(result, msg, value) {
    return {result, msg, value}
}

function verifyPhone(value) {
    let result = true,
        msg = '';

    if (!(/^1[1234567890]\d{9}$/.test(value))) {
        msg = '请输入正确手机号'
        result = false;
    }
    return factory(result, msg, value)
}

function verifyPassword(value) {

    let result = true,
        msg = '';

    if (value.length <= 5) {
        msg = '密码至少6位'
        result = false;
    }
    return factory(result, msg, value)
}

function verifyCode(value) {

    let result = true,
        msg = '';

    if (value.length < 4) {
        msg = '请输入正确验证码'
        result = false;
    }
    return factory(result, msg, value)
}

module.exports = {
    verifyPhone,
    verifyPassword,
    verifyCode
}
