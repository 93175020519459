import request from '@/utils/request'


//  地址创建
export function apiAddressPreserve (data) {
    return request({
        url: 'address/preserve',
        method: 'post',
        data: data,
    })
}
// 地址详情
export function apiAddressDetail (data) {
    return request({
        url: 'address/getInfo ',
        method: 'post',
        data: data,
    })
}
// 地址列表
export function apiAddressList (data) {
    return request({
        url: 'address/getList',
        method: 'post',
        data: data,
    })
}


