<template>
  <div>
    <div class="background-white form">
      <commonHeader :show-dialogue="true" />
      <div class="container">
        <van-field v-model="username" label="收货人" maxlength="10" placeholder="请填写收货人姓名" />
        <van-field v-model="phone" type="number" maxlength="11" label="电话号码" placeholder="请填写收货人手机号" />
        <van-field readonly clickable name="area" :value="value" label="所在地区" placeholder="省市区县，乡镇等"
          @click="showPicker = true" />
        <van-field v-model="address" rows="1" autosize label="详细地址" type="textarea" maxlength="50"
          placeholder="街道，楼牌号等" />
        <van-cell center>
          <template #title>
            <div class="checked-title">设为默认地址</div>
            <div class="checked-text">每次下单时默认使用该地址</div>
          </template>
          <template #right-icon>
            <van-switch v-model="checked" size="24" inactive-color="#EBECF0" active-color="#ff6a4d" />
          </template>
        </van-cell>
      </div>
    </div>
    <footerFixed :dialogue-show="false">
      <pageBottomButton color="red" buttonType="text" text="提交" @click="submit" />
    </footerFixed>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import citys from '@/assets/json/citys.json'
import verification from '@/utils/validate'
import { apiAddressPreserve, apiAddressDetail } from '@/api/address'
import pageBottomButton from '@/components/page-bottom-button'
import footerFixed from '@/components/page-footer-fixed'
import commonMixins from "@/mixins/common";

export default {
  components: {
    pageBottomButton,
    footerFixed,
  },
  mixins: [commonMixins],
  data () {
    return {
      username: '',
      phone: '',
      address: '',
      value: '',

      showPicker: false,
      checked: false,

      columns: [],

      id: 0,
    }
  },
  computed: {},
  mounted () {
    this.$util.setShare()
  },
  methods: {
    toastMsg (msg) {
      this.$toast(msg)
    },

    // 提交请求
    submitRquest (data) {
      apiAddressPreserve(data).then(() => {
        this.$router.go(-1) //返回上一层
      })
    },

    submit () {
      let verificationPhoneResult = verification.verifyPhone(this.phone),
        { username, address } = this
      if (!username) {
        this.toastMsg('请输入收货人信息')
        return false
      }
      if (!verificationPhoneResult.result) {
        this.toastMsg(verificationPhoneResult.msg)
        return false
      }
      if (!address) {
        this.toastMsg('请输入详细地址')
        return false
      }
      let query = {}
      query.consignee = this.username
      query.phone = this.phone
      query.address = this.address
      query.text = this.value
      query.default = this.checked ? 1 : 0

      if (this.id) {
        query.id = this.id
      }

      this.submitRquest(query)
    },

    onConfirm (pickerValue) {
      this.value = pickerValue.join('')
      this.showPicker = false
    },

    // 初始化城市数据
    initCitysColumns () {
      let columns = []
      citys.forEach(function (item1) {
        let o1 = {}
        o1.children = []
        o1.text = item1.text

        item1.areas.forEach(function (item2) {
          let o2 = {}
          o2.children = []
          o2.text = item2.text
          o1.children.push(o2)
          item2.areas.forEach(function (item3) {
            let o3 = {}
            o3.text = item3.text
            o2.children.push(o3)
          })
          if (item2.areas.length === 0) {
            let o3 = {}
            o3.text = ''
            o2.children.push(o3)
          }

        })
        columns.push(o1)
      })

      this.columns = columns
    },
    // 获取地址信息
    getAddressDetail (id) {
      apiAddressDetail({ id }).then((res) => {
        let resdata = res.data
        this.username = resdata.consignee
        this.phone = resdata.phone
        this.address = resdata.address
        this.value = resdata.text
        this.checked = resdata.default === 1 ? true : false
      })
    },
  },

  created () {
    let id = this.$route.query.id

    if (id) {
      this.id = id
      this.getAddressDetail(id)
    }
    this.initCitysColumns()
  },
}
</script>

<style lang="scss" scoped>
.checked {
  &-text {
    font-size: 0.32rem;
  }
}

.van-cell {
  padding: 0.4rem 0;

  &::after {
    width: 100%;
    left: 0;
  }
}

/deep/ .van-picker__confirm {
  color: var(--main-color);
}

/deep/ .van-switch {
  border-color: transparent;

  .van-switch__node {
    box-shadow: none;
  }
}

/deep/ .van-field {
  &__label {
    color: #333;

    font-size: 0.4rem;
  }

  &__label {
    width: 2.08rem;
    margin-right: 0;
  }

  &__control {
    font-size: 0.4rem;
  }
}

.bottom-fixed-button {
  @include maxWidth();

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;

  .button {
    margin: 0.64rem 0;
  }
}

.form {
  //padding-top: 0.5rem;
}
</style>
